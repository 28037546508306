import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={
            "https://static.wixstatic.com/media/58f6f5_5cbdb3862c7449cbb5a799414572b688~mv2.png/v1/fill/w_280,h_112,al_c,lg_1,q_85/PRICIS_logo_def.webp"
          }
          className="App-logo"
          alt="logo"
        />
        <div>
          <p>
            <strong>PRICIS, s.r.o.</strong>
          </p>
          <p>Tř. Tomáše Bati 1716</p>
          <p>765 02 Otrokovice</p>
          <p>IČ: 25550225, DIČ: CZ25550225</p>
          <br />
          <p>
            <strong>Provozovna</strong>
          </p>
          <p>Areál Toma, a.s.</p>
          <p>Tř. Tomáše Bati</p>
          <p>budova č. 51</p>
          <p>Tel./Fax: 577 663 677</p>
          <p>Tel. výroba: 577 663 607</p>
          <p>E-mail: pricis@pricis.cz</p>
          <br />
          <p>
            <strong>Kontaktní osoby</strong>
          </p>
          <p>Stanislav Pricis</p>
          <p>jednatel</p>
          <p>mobil: 603 839 284</p>
        </div>
      </header>
    </div>
  );
}

export default App;
